import { request } from "@/utils/request";

/**
 *
 * @returns {租赁合同页面新增相关电子合同}
 *
 */
//  保证合同
//  生成保证合同
export function genBzContractPDF(data, id) {
  return request({
    url: `/api/manage/contract/genBzContractPDF/${id}`,
    method: "post",
    data,
  });
}

//  查询保证合同信息
export function getBzContractSupplemenById(id) {
  return request({
    url: `/api/manage/contract/getBzContractSupplemenById/${id}`,
    method: "get"
  });
}

//  更新保证合同信息
export function updateBzContractPDF(data, id) {
  return request({
    url: `/api/manage/contract/updateBzContractPDF/${id}`,
    method: "post",
    data,
  });
}

//  下载保证合同信息
export function downloadBzContractPDF(id) {
  return request({
    url: `/api/manage/contract/downloadBzContractPDF/${id}`,
    method: "post",
    responseType: 'blob',//服务器返回的数据类型
    timeout: 500 * 1000,
  });
}



// 买卖二手车
// 生成买卖二手车
export function genEscmmContractPDF(id) {
  return request({
    url: `/api/manage/contract/genEscmmContractPDF/${id}`,
    method: "post"
  });
}

// 更新买卖二手车
export function updateEscmmContractPDF(id) {
  return request({
    url: `/api/manage/contract/updateEscmmContractPDF/${id}`,
    method: "post"
  });
}

// 下载买卖二手车
export function downloadEscmmContractPDF(id) {
  return request({
    url: `/api/manage/contract/downloadEscmmContractPDF/${id}`,
    method: "post",
    responseType: 'blob',//服务器返回的数据类型
    timeout: 500 * 1000,
  });
}

// 还款说明书
// 生成还款说明书
export function genHksmsPDF(id) {
  return request({
    url: `/api/manage/contract/genHksmsPDF/${id}`,
    method: "post"
  });
}

// 更新还款说明书
export function updateHksmstPDF(id) {
  return request({
    url: `/api/manage/contract/updateHksmstPDF/${id}`,
    method: "post"
  });
}

// 下载还款说明书
export function downloadHksmsPDF(id) {
  return request({
    url: `/api/manage/contract/downloadHksmsPDF/${id}`,
    method: "post",
    responseType: 'blob',//服务器返回的数据类型
    timeout: 500 * 1000,
  });
}

// 有偿保证合同
// 生成有偿保证合同
export function genYcbzContractPDF(data, id) {
  return request({
    url: `/api/manage/contract/genYcbzContractPDF/${id}`,
    method: "post",
    data
  });
}

// 更新有偿保证合同
export function updateYcbzContractPDF(data, id) {
  return request({
    url: `/api/manage/contract/updateYcbzContractPDF/${id}`,
    method: "post",
    data
  });
}

// 下载有偿保证合同
export function downloadYcbzContractPDF(id) {
  return request({
    url: `/api/manage/contract/downloadYcbzContractPDF/${id}`,
    method: "post",
    responseType: 'blob',//服务器返回的数据类型
    timeout: 500 * 1000,
  });
}

// 付款通知书
// 生成付款通知书
export function genFktzsPDF(id) {
  return request({
    url: `/api/manage/contract/genFktzsPDF/${id}`,
    method: "post",
  });
}

// 更新付款通知书
export function updateFktzsPDF(id) {
  return request({
    url: `/api/manage/contract/updateFktzsPDF/${id}`,
    method: "post"
  });
}

// 下载付款通知书
export function downloadFktzsPDF(id) {
  return request({
    url: `/api/manage/contract/downloadFktzsPDF/${id}`,
    method: "post",
    responseType: 'blob',//服务器返回的数据类型
    timeout: 500 * 1000,
  });
}

// 发票情况说明
// 生成发票情况说明
export function genFpqksmPDF(id) {
  return request({
    url: `/api/manage/contract/genFpqksmPDF/${id}`,
    method: "post",
  });
}

// 更新发票情况说明
export function updateFpqksmPDF(id) {
  return request({
    url: `/api/manage/contract/updateFpqksmPDF/${id}`,
    method: "post"
  });
}

// 下载发票情况说明
export function downloadFpqksmPDF(id) {
  return request({
    url: `/api/manage/contract/downloadFpqksmPDF/${id}`,
    method: "post",
    responseType: 'blob',//服务器返回的数据类型
    timeout: 500 * 1000,
  });
}

// 承租人单方承诺函
// 生成承租人单方承诺函
export function genCzrdfcnhPDF(id) {
  return request({
    url: `/api/manage/contract/genCzrdfcnhPDF/${id}`,
    method: "post",
  });
}

// 更新承租人单方承诺函
export function updateCzrdfcnhPDF(id) {
  return request({
    url: `/api/manage/contract/updateCzrdfcnhPDF/${id}`,
    method: "post"
  });
}

// 下载承租人单方承诺函
export function downloadCzrdfcnhPDF(id) {
  return request({
    url: `/api/manage/contract/downloadCzrdfcnhPDF/${id}`,
    method: "post",
    responseType: 'blob',//服务器返回的数据类型
    timeout: 500 * 1000,
  });
}

// 下载声明书
// 新经营性租赁合同-声明书-关于到期未过户保险事宜-清洁版
export function downloadDqwghsysmsPDF() {
  return request({
    url: `/api/manage/contract/downloadDqwghsysmsPDF`,
    method: "get",
    responseType: 'blob',//服务器返回的数据类型
    timeout: 500 * 1000,
  });
}